import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  doc,
  writeBatch,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from './config';
import { Comedian, Round, Votes, AllVotes } from '../types';

interface VotingStatus {
  isActive: boolean;
  activeRoundId: string | null;
}

export const firestoreServices = {
  // Fetch comedians
  fetchComedians: async () => {
    const comediansCollection = collection(db, 'comedians');
    const snapshot = await getDocs(comediansCollection);
    return snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() }) as Comedian,
    );
  },

  // Fetch rounds
  fetchRounds: async () => {
    const roundsCollection = collection(db, 'rounds');
    const snapshot = await getDocs(roundsCollection);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as Round);
  },

  // Fetch the active voting status
  fetchGlobalVotingStatus: async () => {
    const statusRef = doc(db, 'config', 'votingStatus');
    const docSnapshot = await getDoc(statusRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data(); // Returns { isActive, activeRoundId }
    } else {
      return { isActive: false, activeRoundId: null }; // Default if not set
    }
  },

  // Fetch the current comedian performing
  fetchCurrentComedianId: async () => {
    const comedianRef = doc(db, 'config', 'currentComedianId');
    const docSnapshot = await getDoc(comedianRef);
    if (docSnapshot.exists()) {
      return docSnapshot.data().comedianId;
    } else {
      return ''; // Default if not set
    }
  },

  fetchUserVotesForRound: async (userPin: string, roundId: string) => {
    const votesCollectionRef = collection(db, 'votes');
    const snapshot = await getDocs(
      query(
        votesCollectionRef,
        where('userPin', '==', userPin),
        where('roundId', '==', roundId),
      ),
    );

    const userVotes: Votes = {};
    snapshot.forEach((doc) => {
      const data = doc.data();
      userVotes[data.comedianId] = data.score;
    });

    return userVotes; // Returns an object with comedianId as key and score as value
  },

  fetchActiveRound: async (): Promise<Round | null> => {
    const roundsRef = collection(db, 'rounds');
    const querySnapshot = await getDocs(
      query(roundsRef, where('isActive', '==', true)),
    );
    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0];
      const activeRoundData = docSnapshot.data() as Round;
      return {
        ...activeRoundData,
        id: docSnapshot.id, // Explicitly set the 'id' field
      };
    }
    return null;
  },

  fetchAllVotesForRound: (
    roundId: string,
    callback: (votesData: AllVotes) => void,
  ) => {
    const votesRef = collection(db, 'votes');
    return onSnapshot(
      query(votesRef, where('roundId', '==', roundId)),
      (snapshot) => {
        const votesData: AllVotes = {};
        snapshot.forEach((doc) => {
          const vote = doc.data();
          const { comedianId, score } = vote;
          if (!votesData[comedianId]) {
            votesData[comedianId] = [];
          }
          votesData[comedianId].push(score);
        });
        callback(votesData); // Callback with votes organized by comedianId
      },
    );
  },

  // Upload an avatar for a comedian
  uploadAvatar: async (file: File): Promise<string> => {
    const storageRef = ref(storage, `avatars/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  },

  // Add a new comedian
  addComedian: async (name: string, avatarUrl: string): Promise<string> => {
    const docRef = await addDoc(collection(db, 'comedians'), {
      name: name,
      avatarUrl,
    });
    return docRef.id;
  },

  // Edit a comedian
  editComedian: async (comedianId: string, newName: string) => {
    const comedianDoc = doc(db, 'comedians', comedianId);
    await updateDoc(comedianDoc, { name: newName });
  },

  // Delete a comedian
  deleteComedian: async (comedianId: string) => {
    const comedianDoc = doc(db, 'comedians', comedianId);
    await deleteDoc(comedianDoc);
  },

  // Add a new round
  addRound: async (round: Round): Promise<string> => {
    // Remove the id field before updating the doc in firebase
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...roundWithoutId } = round;
    const docRef = await addDoc(collection(db, 'rounds'), roundWithoutId);
    return docRef.id;
  },

  // Edit a round
  editRound: async (roundId: string, updatedRound: Round) => {
    const roundDoc = doc(db, 'rounds', roundId);
    // Remove the id field before updating the doc in firebase
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...roundWithoutId } = updatedRound;
    await updateDoc(roundDoc, roundWithoutId);
  },

  // Delete a round
  deleteRound: async (roundId: string) => {
    const roundDoc = doc(db, 'rounds', roundId);
    await deleteDoc(roundDoc);
  },

  // Set a round as active and the others as inactive
  setActiveRound: async (activeRoundId: string, toggleRoundOff: boolean) => {
    const roundsCollectionRef = collection(db, 'rounds');

    // If toggleRoundOff is true, set just the active round to false, otherwise we set the active round to true and the others to false
    if (toggleRoundOff) {
      const activeRoundRef = doc(db, 'rounds', activeRoundId);
      await updateDoc(activeRoundRef, { isActive: false });
    } else {
      const batch = writeBatch(db);
      const snapshot = await getDocs(roundsCollectionRef);
      snapshot.docs.forEach((docSnapshot) => {
        const roundRef = doc(db, 'rounds', docSnapshot.id);
        batch.update(roundRef, { isActive: docSnapshot.id === activeRoundId });
      });
      await batch.commit();
    }
  },

  // Update the global voting status
  setGlobalVotingStatus: async (isActive: boolean, activeRoundId: string) => {
    const statusRef = doc(db, 'config', 'votingStatus');
    await setDoc(statusRef, { isActive, activeRoundId });
  },

  // Update the current comedian performing
  setCurrentComedianId: async (comedianId: string) => {
    const statusRef = doc(db, 'config', 'currentComedianId');
    await setDoc(statusRef, { comedianId });
  },

  // Submit a vote for a comedian in a round, or if the user has already voted for that comedian in that round, update the score
  submitOrUpdateVote: async (
    userPin: string,
    comedianId: string,
    roundId: string,
    score: number,
  ) => {
    const votesCollectionRef = collection(db, 'votes');
    const querySnapshot = await getDocs(
      query(
        votesCollectionRef,
        where('userPin', '==', userPin),
        where('comedianId', '==', comedianId),
        where('roundId', '==', roundId),
      ),
    );

    if (!querySnapshot.empty) {
      // User has already voted for this comedian in this round, update the vote
      const voteDoc = querySnapshot.docs[0];
      await updateDoc(voteDoc.ref, { score });
    } else {
      // Submit a new vote
      await addDoc(votesCollectionRef, { userPin, comedianId, roundId, score });
    }
  },

  onVotingStatusChange: (callback: (status: VotingStatus) => void) => {
    const statusRef = doc(db, 'config', 'votingStatus');
    return onSnapshot(statusRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const statusData = docSnapshot.data() as VotingStatus;
        callback(statusData); // { isActive: boolean, activeRoundId: string }
      } else {
        callback({ isActive: false, activeRoundId: null }); // Default if not set
      }
    });
  },

  onCurrentComedianChange: (callback: (comedianId: string) => void) => {
    const comedianRef = doc(db, 'config', 'currentComedianId');
    return onSnapshot(comedianRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const comedianData = docSnapshot.data();
        callback(comedianData.comedianId);
      } else {
        callback(''); // Default if not set
      }
    });
  },
};
