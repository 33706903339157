import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './pages/admin/Login';
import AdminHome from './pages/admin/Home';
import AudienceHome from './pages/audience/Home';

const App: React.FC = () => {
  const [isAdminAuthenticated, setAdminAuthenticated] =
    useState<boolean>(false);

  const handleLoginSuccess = () => {
    setAdminAuthenticated(true);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AudienceHome />} />
        <Route
          path="/admin/login"
          element={<AdminLogin onLoginSuccess={handleLoginSuccess} />}
        />
        <Route
          path="/admin"
          element={<AdminHome isAdminAuthenticated={isAdminAuthenticated} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
