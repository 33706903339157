import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import { Comedian, Round, ComedianInRound } from '../../types';

interface RoundManagementModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: string | Round) => void; // Union type for string and Round
  comedians: Comedian[];
  roundToEdit?: Round; // Undefined when adding a new round
}

const RoundManagementModal: React.FC<RoundManagementModalProps> = ({
  isOpen,
  onClose,
  onSave,
  comedians,
  roundToEdit,
}) => {
  const [roundName, setRoundName] = useState<string>('');
  const [selectedComedians, setSelectedComedians] = useState<ComedianInRound[]>(
    [],
  );

  useEffect(() => {
    if (roundToEdit) {
      setRoundName(roundToEdit.name);
      setSelectedComedians(roundToEdit.comedians);
    }
  }, [roundToEdit]);

  const handleSave = () => {
    if (roundToEdit) {
      // Editing an existing round
      const updatedRound: Round = {
        ...roundToEdit,
        name: roundName,
        comedians: selectedComedians,
      };
      onSave(updatedRound);
    } else {
      // Adding a new round
      onSave(roundName);
    }
    // Clear the round name and selected comedians
    setRoundName('');
    setSelectedComedians([]);
    onClose();
  };

  const handleComedianSelection = (comedianId: string, isSelected: boolean) => {
    setSelectedComedians((prev) => {
      if (isSelected) {
        const maxPosition = prev.length
          ? Math.max(...prev.map((c) => c.position))
          : 0;
        return [...prev, { id: comedianId, position: maxPosition + 1 }];
      } else {
        return prev
          .filter((comedian) => comedian.id !== comedianId)
          .map((comedian, index) => ({ ...comedian, position: index + 1 }));
      }
    });
  };

  const moveComedian = (index: number, direction: 'up' | 'down') => {
    setSelectedComedians((prev) => {
      const newOrder = [...prev];
      const newIndex = direction === 'up' ? index - 1 : index + 1;

      if (newIndex < 0 || newIndex >= newOrder.length) return prev;

      [newOrder[index], newOrder[newIndex]] = [
        newOrder[newIndex],
        newOrder[index],
      ];
      return newOrder.map((comedian, i) => ({ ...comedian, position: i + 1 }));
    });
  };

  const orderedSelectedComedians = selectedComedians.sort(
    (a, b) => a.position - b.position,
  );
  const nonSelectedComedians = comedians.filter(
    (comedian) => !selectedComedians.some((sc) => sc.id === comedian.id),
  );

  return (
    <Modal
      title={roundToEdit ? 'Edit Round' : 'Add Round'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <h3 style={{ margin: 0, paddingBottom: '5px' }}>Name</h3>
      <input
        type="text"
        value={roundName}
        placeholder="First Round"
        onChange={(e) => setRoundName(e.target.value)}
        style={{
          fontFamily: '"Pixel Operator", sans-serif',
          fontSize: '16px',
          color: 'black',
          backgroundColor: 'white',
          border: '1px solid grey',
          padding: '10px',
        }}
      />
      {roundToEdit && ( // when adding a new round we only enter the name
        <div>
          <h3 style={{ margin: 0, paddingBottom: '5px', paddingTop: '5px' }}>
            Comedians in Round
          </h3>
          {orderedSelectedComedians.map((selectedComedian, index) => {
            const comedian = comedians.find(
              (c) => c.id === selectedComedian.id,
            );
            if (!comedian) return null;

            return (
              <div
                key={comedian.id}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 0',
                }}
              >
                <input
                  type="checkbox"
                  checked
                  onChange={(e) =>
                    handleComedianSelection(comedian.id, e.target.checked)
                  }
                />
                <span style={{ fontSize: '20px', marginLeft: '10px' }}>
                  {comedian.name}
                </span>
                <div style={{ marginLeft: 'auto' }}>
                  <button onClick={() => moveComedian(index, 'up')}>+</button>
                  <button onClick={() => moveComedian(index, 'down')}>-</button>
                </div>
              </div>
            );
          })}
          {nonSelectedComedians.map((comedian) => (
            <div
              key={comedian.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 0',
              }}
            >
              <input
                type="checkbox"
                checked={selectedComedians.some((sc) => sc.id === comedian.id)}
                onChange={(e) =>
                  handleComedianSelection(comedian.id, e.target.checked)
                }
              />
              <span style={{ fontSize: '20px', marginLeft: '10px' }}>
                {comedian.name}
              </span>
            </div>
          ))}
        </div>
      )}
      <div
        onClick={handleSave}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontFamily: '"Pixel Operator", sans-serif',
          fontSize: '20px',
          color: '#9E9E9E',
          border: '1px solid grey',
          justifyContent: 'center',
          padding: '10px',
          marginTop: '20px',
        }}
        onMouseOver={(e) => (e.currentTarget.style.color = '#81C784')}
        onMouseOut={(e) => (e.currentTarget.style.color = '#9E9E9E')}
      >
        Save
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          style={{
            width: '25px',
            height: '25px',
            marginLeft: '10px',
          }}
        >
          <path
            d="M4 2h14v2H4v16h2v-6h12v6h2V6h2v16H2V2h2zm4 18h8v-4H8v4zM20 6h-2V4h2v2zM6 6h9v4H6V6z"
            fill="currentColor"
          />
        </svg>
      </div>
    </Modal>
  );
};

export default RoundManagementModal;
