import { useState, useEffect } from 'react';
import { firestoreServices } from '../../firebase/services';
import { Comedian, Votes, Round } from '../../types';
import comicsCabalLogo from '../../assets/imgs/comicsCabalLogo.png';
import instagramPixelLogo from '../../assets/imgs/instagram_pixel_logo_icon.png';
import brawlRatingActive1 from '../../assets/imgs/brawl_rating_active-1.png';
import brawlRatingActive2 from '../../assets/imgs/brawl_rating_active-2.png';
import brawlRatingActive3 from '../../assets/imgs/brawl_rating_active-3.png';
import brawlRatingActive4 from '../../assets/imgs/brawl_rating_active-4.png';
import brawlRatingInactive1 from '../../assets/imgs/brawl_rating_inactive-1.png';
import brawlRatingInactive2 from '../../assets/imgs/brawl_rating_inactive-2.png';
import brawlRatingInactive3 from '../../assets/imgs/brawl_rating_inactive-3.png';
import brawlRatingInactive4 from '../../assets/imgs/brawl_rating_inactive-4.png';

const AudienceHome = () => {
  const [userPin, setUserPin] = useState('');
  const [isPinEntered, setIsPinEntered] = useState(false);
  const [votes, setVotes] = useState<Votes>({});
  const [comedians, setComedians] = useState<Comedian[]>([]);
  const [activeRound, setActiveRound] = useState<Round | null>(null);
  const [isVotingActive, setIsVotingActive] = useState(false);
  const [votesLoading, setIsVotesLoading] = useState(false);
  const [selectedComedianId, setSelectedComedianId] = useState<string>('');

  //check if user has a stored pin and set it if they do
  useEffect(() => {
    const storedPin = localStorage.getItem('votingPin');
    if (storedPin) {
      setUserPin(storedPin);
      setIsPinEntered(true);
    }
  }, []);

  function generatePIN() {
    // creates a random 10 character alphanumeric pin
    const array = new Uint32Array(2);
    window.crypto.getRandomValues(array);
    const pin = (array[0].toString(36) + array[1].toString(36)).substring(
      0,
      10,
    );
    localStorage.setItem('votingPIN', JSON.stringify({ pin: pin }));
    return pin;
  }

  function getPIN() {
    const storedPIN = localStorage.getItem('votingPIN');
    if (storedPIN) {
      const { pin } = JSON.parse(storedPIN);
      return pin;
    } else {
      localStorage.removeItem('votingPIN');
    }
    return generatePIN();
  }

  useEffect(() => {
    let unsubscribeFromVotingStatus: () => void;

    const fetchAndSetData = async () => {
      const fetchedComedians = await firestoreServices.fetchComedians();
      setComedians(fetchedComedians);

      const fetchedActiveRound = await firestoreServices.fetchActiveRound();
      setActiveRound(fetchedActiveRound);
    };
    if (isPinEntered) {
      unsubscribeFromVotingStatus = firestoreServices.onVotingStatusChange(
        async (status) => {
          setIsVotingActive(status.isActive);
          if (status.isActive) {
            await fetchAndSetData();
          }
          setIsVotesLoading(false);
        },
      );
    }

    return () => {
      if (unsubscribeFromVotingStatus) {
        unsubscribeFromVotingStatus();
      }
    };
  }, [isPinEntered]);

  const handleShowEnter = () => {
    const pin = getPIN();
    setUserPin(pin);
    setIsVotesLoading(true); // doing it here prevents anything else from showing up besides loading screen
    setIsPinEntered(true);
  };

  useEffect(() => {
    const fetchVotes = async () => {
      if (userPin && activeRound) {
        const votes = await firestoreServices.fetchUserVotesForRound(
          userPin,
          activeRound.id,
        );
        setVotes(votes);
      }
    };

    fetchVotes();
  }, [userPin, activeRound]);

  // listener that controls who is the current performing comedian within the round
  useEffect(() => {
    const unsubscribe = firestoreServices.onCurrentComedianChange(
      (comedianId: string) => {
        setSelectedComedianId(comedianId);
      },
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const handleVote = async (comedianId: string, score: number) => {
    if (!activeRound) {
      alert('cannot vote for a round that does not exist');
      return;
    }

    // Save the previous state
    const previousVotes = { ...votes };
    // Optimistically update the state
    setVotes({ ...votes, [comedianId]: score });

    try {
      await firestoreServices.submitOrUpdateVote(
        userPin,
        comedianId,
        activeRound.id,
        score,
      );
    } catch (error) {
      // Revert back to the previous state
      setVotes(previousVotes);
      alert('Failed to submit vote: ' + error);
    }
  };

  const orderedComedians =
    activeRound?.comedians
      ?.sort((a, b) => a.position - b.position)
      ?.map((orderedComedian) =>
        comedians.find((c) => c.id === orderedComedian.id),
      ) || [];

  const isSelected = (comedianId: string, score: number) =>
    votes[comedianId] === score;

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getEmojiImage = (score: number, isSelected: boolean) => {
    const images: { [key: number]: string } = {
      1: isSelected ? brawlRatingActive1 : brawlRatingInactive1,
      2: isSelected ? brawlRatingActive2 : brawlRatingInactive2,
      3: isSelected ? brawlRatingActive3 : brawlRatingInactive3,
      4: isSelected ? brawlRatingActive4 : brawlRatingInactive4,
    };
    return images[score];
  };

  return (
    <div>
      <h5 style={{ textAlign: 'center' }}>COMICS CABAL BRAWL</h5>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-20px',
        }}
      >
        <img
          src={comicsCabalLogo}
          style={{
            width: window.innerWidth > 768 ? '5%' : '12%',
            marginRight: '10px',
            cursor: 'pointer',
          }}
          onClick={() => openInNewTab('https://www.instagram.com/comicscabal/')}
        />
        <img
          src={instagramPixelLogo}
          style={{
            width: window.innerWidth > 768 ? '3.5%' : '8.5%',
            marginLeft: '10px',
            cursor: 'pointer',
          }}
          onClick={() => openInNewTab('https://www.instagram.com/comicscabal/')}
        />
      </div>
      {isPinEntered ? (
        votesLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <h6>Loading the funny people...</h6>
          </div>
        ) : isVotingActive ? (
          <>
            <h6
              style={{
                textAlign: 'center',
                lineHeight: '1.5',
                marginTop: '5px',
                marginBottom: 'px',
              }}
            >
              VOTE AFTER EACH
              <br />
              COMEDIAN PERFORMS!
            </h6>
            <div>
              <ul
                style={{
                  listStyle: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 0,
                  margin: 0,
                  overflow: 'auto',
                }}
              >
                {orderedComedians.map(
                  (comedian) =>
                    comedian && (
                      <li
                        key={comedian.id}
                        style={{
                          border:
                            selectedComedianId === comedian.id
                              ? '2px solid #661fbd'
                              : '2px solid #431d72',
                          boxShadow:
                            selectedComedianId === comedian.id
                              ? '0px 0px 6px 7px #431d72'
                              : '0px 0px 2px 2px #301551',
                          paddingRight: '20px',
                          paddingTop: '5px',
                          paddingBottom: '15px',
                          margin: '10px auto',
                          width: '90%',
                          maxWidth: '600px',
                          wordWrap: 'break-word',
                          backgroundColor: '#00000d',
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <img
                            src={comedian.avatarUrl}
                            alt={comedian.name}
                            width="25%"
                            height="auto"
                          />
                          <div style={{ flexGrow: 1, paddingLeft: '5px' }}>
                            <h4
                              style={{
                                color: 'white',
                                textTransform: 'uppercase',
                              }}
                            >
                              {comedian.name}
                            </h4>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                              }}
                            >
                              {['😐', '😏', '😀', '🤣'].map((_, index) => {
                                const score = 1 + index;
                                return (
                                  <img
                                    key={score}
                                    onClick={() =>
                                      handleVote(comedian.id, score)
                                    }
                                    src={getEmojiImage(
                                      score,
                                      isSelected(comedian.id, score),
                                    )}
                                    alt={`Rating ${score}`}
                                    style={{
                                      width:
                                        window.innerWidth <= 768
                                          ? '30px'
                                          : '50px',
                                      cursor: 'pointer',
                                      paddingRight: '10px',
                                      marginTop: '-10px',
                                    }}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </li>
                    ),
                )}
              </ul>
            </div>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingTop: '30px',
              paddingLeft: '50px',
              paddingRight: '50px',
            }}
          >
            <h4>Our fighters are charging up for the next brawl...</h4>
            <h4>Voting is closed</h4>
          </div>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          <div
            onClick={handleShowEnter}
            style={{
              padding: '10px 20px',
              margin: '10px',
              fontSize: '16px',
              color: 'black',
              border: '2px solid #661fbd',
              boxShadow: '0px 0px 5px 5px #431d72',
              cursor: 'pointer',
              outline: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h4 style={{ color: 'white' }}>ENTER THE BRAWL</h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudienceHome;
