import React from 'react';
import { Round } from '../../types';

interface RoundListProps {
  rounds: Round[];
  onAdd: (roundName: string) => void;
  onEdit: (updatedRound: Round) => void;
  onDelete: (roundId: string) => void;
  makeRoundActive: (roundId: string) => void;
}

const RoundList: React.FC<RoundListProps> = ({
  rounds,
  onAdd,
  onEdit,
  onDelete,
  makeRoundActive,
}) => {
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {window.innerWidth <= 768 ? <h5>Rounds</h5> : <h2>Rounds</h2>}
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          onClick={() => onAdd('New Round')}
          style={{
            width: '25px',
            height: '25px',
            cursor: 'pointer',
            paddingLeft: '10px',
            color: '#4CAF50',
          }}
          onMouseOver={(e) => (e.currentTarget.style.color = '#81C784')}
          onMouseOut={(e) => (e.currentTarget.style.color = '#4CAF50')}
        >
          {' '}
          <path
            d="M3 3h18v18H3V3zm16 16V5H5v14h14zm-6-8h4v2h-4v4h-2v-4H7v-2h4V7h2v4z"
            fill="currentColor"
          />{' '}
        </svg>
      </div>
      {rounds.map((round) => (
        <div
          key={round.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            font: 'Pixel Operator',
            fontSize: window.innerWidth <= 768 ? '14px' : '20px',
          }}
        >
          <div style={{ flexGrow: 1 }}>{round.name}</div>
          <div>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={() => onEdit(round)}
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                paddingLeft: '10px',
                verticalAlign: 'middle',
                color: '#9E9E9E',
              }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#E0E0E0')}
              onMouseOut={(e) => (e.currentTarget.style.color = '#9E9E9E')}
            >
              {' '}
              <path
                d="M18 2h-2v2h-2v2h-2v2h-2v2H8v2H6v2H4v2H2v6h6v-2h2v-2h2v-2h2v-2h2v-2h2v-2h2V8h2V6h-2V4h-2V2zm0 8h-2v2h-2v2h-2v2h-2v2H8v-2H6v-2h2v-2h2v-2h2V8h2V6h2v2h2v2zM6 16H4v4h4v-2H6v-2z"
                fill="currentColor"
              />{' '}
            </svg>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={() => onDelete(round.id)}
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                color: 'red',
                paddingLeft: '10px',
                verticalAlign: 'middle',
              }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#ff7f7f')}
              onMouseOut={(e) => (e.currentTarget.style.color = 'red')}
            >
              {' '}
              <path
                d="M5 5h2v2H5V5zm4 4H7V7h2v2zm2 2H9V9h2v2zm2 0h-2v2H9v2H7v2H5v2h2v-2h2v-2h2v-2h2v2h2v2h2v2h2v-2h-2v-2h-2v-2h-2v-2zm2-2v2h-2V9h2zm2-2v2h-2V7h2zm0 0V5h2v2h-2z"
                fill="currentColor"
              />{' '}
            </svg>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={() => makeRoundActive(round.id)}
              style={{
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                color: round.isActive ? '#4CAF50' : '#9E9E9E',
                paddingLeft: '10px',
                verticalAlign: 'middle',
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.color = round.isActive
                  ? '#81C784'
                  : '#E0E0E0')
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.color = round.isActive
                  ? '#4CAF50'
                  : '#9E9E9E')
              }
            >
              {' '}
              <path
                d={
                  round.isActive
                    ? 'M5 3H3v18h18V3H5zm0 2h14v14H5V5zm4 7H7v2h2v2h2v-2h2v-2h2v-2h2V8h-2v2h-2v2h-2v2H9v-2z'
                    : 'M3 3h18v18H3V3zm16 16V5H5v14h14z'
                }
                fill="currentColor"
              />{' '}
            </svg>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RoundList;
