import React from 'react';
import { Round, Comedian, Result } from '../../types';

interface SelectedRoundProps {
  round: Round | undefined;
  comedians: Comedian[];
  isVotingActive: boolean;
  currentComedianId: string;
  setCurrentComedianId: (comedianId: string) => void;
  toggleVote: () => void;
  votingResults: Record<string, Result>;
}

const SelectedRound: React.FC<SelectedRoundProps> = ({
  round,
  comedians,
  isVotingActive,
  currentComedianId,
  setCurrentComedianId,
  toggleVote,
  votingResults,
}) => {
  if (!round) return <div>No active round selected</div>;

  const participatingComedians = comedians.filter((comedian) =>
    round.comedians.some((c) => c.id === comedian.id),
  );

  const renderComedianRow = (comedian: Comedian) => {
    const result = votingResults[comedian.id];

    return (
      <tr key={comedian.id}>
        <td style={{ padding: '10px', textAlign: 'left' }}>{comedian.name}</td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          {result?.totalScore ? parseFloat(result.totalScore.toFixed(2)) : 0}
        </td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          {result?.averageScore
            ? parseFloat(result.averageScore.toFixed(2))
            : 0}
        </td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          {result?.numberOfVoters ? result.numberOfVoters : 0}
        </td>
        <td style={{ padding: '10px', textAlign: 'center' }}>
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={() =>
              setCurrentComedianId(
                currentComedianId === comedian.id ? '' : comedian.id,
              )
            }
            style={{
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              color: currentComedianId === comedian.id ? '#4CAF50' : '#9E9E9E',
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.color =
                currentComedianId === comedian.id ? '#81C784' : '#E0E0E0')
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.color =
                currentComedianId === comedian.id ? '#4CAF50' : '#9E9E9E')
            }
          >
            <path
              d={
                currentComedianId === comedian.id
                  ? 'M5 3H3v18h18V3H5zm0 2h14v14H5V5zm4 7H7v2h2v2h2v-2h2v-2h2v-2h2V8h-2v2h-2v2h-2v2H9v-2z'
                  : 'M3 3h18v18H3V3zm16 16V5H5v14h14z'
              }
              fill="currentColor"
            />
          </svg>
        </td>
      </tr>
    );
  };

  return (
    <div style={{ paddingTop: '10px' }}>
      {window.innerWidth <= 768 ? (
        <h4 style={{ textAlign: 'center' }}>Selected Round</h4>
      ) : (
        <h1 style={{ textAlign: 'center' }}>Selected Round</h1>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {window.innerWidth <= 768 ? (
          <h5>Round: {round.name}</h5>
        ) : (
          <h2>Round: {round.name}</h2>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {window.innerWidth <= 768 ? (
            <h5>Voting Active</h5>
          ) : (
            <h3>Voting Active</h3>
          )}
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={() => toggleVote()}
            style={{
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              color: isVotingActive ? '#4CAF50' : '#9E9E9E',
              paddingLeft: '10px',
              verticalAlign: 'middle',
            }}
            onMouseOver={(e) =>
              (e.currentTarget.style.color = isVotingActive
                ? '#81C784'
                : '#E0E0E0')
            }
            onMouseOut={(e) =>
              (e.currentTarget.style.color = isVotingActive
                ? '#4CAF50'
                : '#9E9E9E')
            }
          >
            <path
              d={
                isVotingActive
                  ? 'M5 3H3v18h18V3H5zm0 2h14v14H5V5zm4 7H7v2h2v2h2v-2h2v-2h2v-2h2V8h-2v2h-2v2h-2v2H9v-2z'
                  : 'M3 3h18v18H3V3zm16 16V5H5v14h14z'
              }
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ borderBottom: '2px solid #ddd', padding: '10px' }}>
              Comedians
            </th>
            <th style={{ borderBottom: '2px solid #ddd', padding: '10px' }}>
              Total
            </th>
            <th style={{ borderBottom: '2px solid #ddd', padding: '10px' }}>
              Average
            </th>
            <th style={{ borderBottom: '2px solid #ddd', padding: '10px' }}>
              Voters
            </th>
            <th style={{ borderBottom: '2px solid #ddd', padding: '10px' }}>
              Performing
            </th>
          </tr>
        </thead>
        <tbody>{participatingComedians.map(renderComedianRow)}</tbody>
      </table>
    </div>
  );
};

export default SelectedRound;
