// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDwpOm7hgNPYYd8Bsonyj2J-_c3JHg-Ozg',
  authDomain: 'comics-cabal.firebaseapp.com',
  projectId: 'comics-cabal',
  storageBucket: 'comics-cabal.appspot.com',
  messagingSenderId: '321885918147',
  appId: '1:321885918147:web:69ffcb802a2a17610b6376',
  measurementId: 'G-0YQ8DZLWYL',
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const analytics = getAnalytics(firebase);
export const storage = getStorage(firebase);
export const auth = getAuth(firebase);
