import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase/config';

interface AdminLoginProps {
  onLoginSuccess: () => void;
}

const AdminLogin: React.FC<AdminLoginProps> = ({ onLoginSuccess }) => {
  const [password, setPassword] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, 'admin@admin.com', password)
      .then(() => {
        onLoginSuccess();
        navigate('/admin');
      })
      .catch((error) => {
        // Handle incorrect password
        alert('Login failed: ' + error.message);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '50px',
      }}
    >
      {window.innerWidth <= 768 ? (
        <h5
          style={{
            margin: 0,
            paddingBottom: '5px',
          }}
        >
          Enter the Super Secret Password
        </h5>
      ) : (
        <h2
          style={{
            margin: 0,
            paddingBottom: '5px',
          }}
        >
          Enter the Super Secret Password
        </h2>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
          justifyContent: 'space-between',
          paddingTop: '10px',
        }}
      >
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{
            fontFamily: '"Pixel Operator", sans-serif',
            fontSize: '16px',
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid grey',
            padding: '10px',
            marginRight: '10px',
          }}
        />
        <div
          onClick={handleLogin}
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontFamily: '"Pixel Operator", sans-serif',
            fontSize: '20px',
            color: '#9E9E9E',
            justifyContent: 'center',
          }}
          onMouseOver={(e) => (e.currentTarget.style.color = '#81C784')}
          onMouseOut={(e) => (e.currentTarget.style.color = '#9E9E9E')}
        >
          Login
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            style={{
              width: '25px',
              height: '25px',
              marginLeft: '10px',
            }}
          >
            <path
              d="M5 3H3v4h2V5h14v14H5v-2H3v4h18V3H5zm12 8h-2V9h-2V7h-2v2h2v2H3v2h10v2h-2v2h2v-2h2v-2h2v-2z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
