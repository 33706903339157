import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ComedianList from '../../components/admin/ComedianList';
import RoundList from '../../components/admin/RoundList';
import SelectedRound from '../../components/admin/SelectedRound';
import Modal from '../../components/Modal';
import RoundManagementModal from '../../components/admin/RoundManagementModal';
import { Comedian, Round, Result } from '../../types';
import { firestoreServices } from '../../firebase/services';

interface AdminHomeProps {
  isAdminAuthenticated: boolean;
}

const AdminHome: React.FC<AdminHomeProps> = ({ isAdminAuthenticated }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAdminAuthenticated) {
      navigate('/admin/login');
    }
  }, [isAdminAuthenticated, navigate]);

  const [comedians, setComedians] = useState<Comedian[]>([]);
  const [rounds, setRounds] = useState<Round[]>([]);
  const selectedRound: Round | undefined = rounds.find(
    (round) => round.isActive,
  );
  const [isVotingActive, setIsVotingActive] = useState<boolean>(false);
  const [currentComedianId, setCurrentComedianId] = useState<string>('');

  useEffect(() => {
    const loadData = async () => {
      const [comediansData, roundsData, votingStatus, currComedianPerforming] =
        await Promise.all([
          firestoreServices.fetchComedians(),
          firestoreServices.fetchRounds(),
          firestoreServices.fetchGlobalVotingStatus(),
          firestoreServices.fetchCurrentComedianId(),
        ]);
      setIsVotingActive(votingStatus.isActive);
      setComedians(comediansData);
      setRounds(roundsData);
      setCurrentComedianId(currComedianPerforming);
    };
    loadData();
  }, []);

  const [votingResults, setVotingResults] = useState<Record<string, Result>>(
    {},
  );

  useEffect(() => {
    if (selectedRound) {
      const unsubscribe = firestoreServices.fetchAllVotesForRound(
        selectedRound.id,
        (votes) => {
          // Process votes and calculate results
          const results: Record<string, Result> = {};
          for (const comedianId in votes) {
            const scores = votes[comedianId];
            const totalScore = scores.reduce((acc, score) => acc + score, 0);
            const averageScore =
              scores.length > 0 ? totalScore / scores.length : 0;
            results[comedianId] = {
              totalScore,
              averageScore,
              numberOfVoters: scores.length,
            };
          }
          setVotingResults(results);
        },
      );
      return () => unsubscribe();
    }
  }, [selectedRound]);

  const [showMangageComedianModal, setShowMangageComedianModal] =
    useState(false);
  const [comedianModalMode, setComedianModalMode] = useState<'add' | 'edit'>(
    'add',
  );
  const [newComedianName, setNewComedianName] = useState('');
  const [newComedianAvatar, setComedianAvatar] = useState<File | null>(null);
  const [editingComedianId, setEditingComedianId] = useState<string | null>(
    null,
  );
  const [showRoundModal, setShowRoundModal] = useState<boolean>(false);
  const [roundBeingEdited, setRoundBeingEdited] = useState<Round | undefined>(
    undefined,
  );

  // Comedian Management Helpers
  const addComedian = async (name: string, file: File | null) => {
    try {
      let avatarUrl = '';
      if (file) {
        avatarUrl = await firestoreServices.uploadAvatar(file);
      }

      const newId = await firestoreServices.addComedian(name, avatarUrl);
      const newComedian = { name, id: newId, avatarUrl };
      setComedians([...comedians, newComedian]);
    } catch (error) {
      alert('There was an error adding the comedian:\n' + error);
    }
  };

  const editComedian = async (id: string, newName: string) => {
    try {
      await firestoreServices.editComedian(id, newName);
      const updatedComedians = comedians.map((comedian) =>
        comedian.id === id ? { ...comedian, name: newName } : comedian,
      );
      setComedians(updatedComedians);
    } catch (error) {
      alert('There was an error editing the comedian:\n' + error);
    }
  };

  const deleteComedian = async (id: string) => {
    // If the comedian is in the round actively being voted on, don't allow deletion
    if (
      isVotingActive &&
      selectedRound &&
      selectedRound.comedians.some((c) => c.id === id)
    ) {
      alert(
        'Please disable the voting before deleting a comedian in the active round.',
      );
      return;
    }
    try {
      await firestoreServices.deleteComedian(id);
      const updatedComedians = comedians.filter(
        (comedian) => comedian.id !== id,
      );
      setComedians(updatedComedians);
    } catch (error) {
      alert('There was an error deleting the comedian:\n' + error);
    }
  };

  const handleOpenAddComedianModal = () => {
    setNewComedianName('');
    setEditingComedianId(null);
    setComedianModalMode('add');
    setShowMangageComedianModal(true);
  };

  const handleOpenEditComedianModal = (name: string, id: string) => {
    // If the comedian is in the round actively being voted on, don't allow editing
    if (
      isVotingActive &&
      selectedRound &&
      selectedRound.comedians.some((c) => c.id === id)
    ) {
      alert(
        'Please disable the voting before editing a comedian in the active round.',
      );
      return;
    }
    setNewComedianName(name);
    setEditingComedianId(id);
    setComedianModalMode('edit');
    setShowMangageComedianModal(true);
  };

  const handleSaveComedian = () => {
    if (comedianModalMode === 'add' && newComedianName.trim() !== '') {
      addComedian(newComedianName, newComedianAvatar);
    } else if (comedianModalMode === 'edit' && editingComedianId) {
      const currentComedian = comedians.find(
        (comedian) => comedian.id === editingComedianId,
      );
      if (currentComedian && currentComedian.name !== newComedianName.trim()) {
        editComedian(editingComedianId, newComedianName);
      }
    }
    setShowMangageComedianModal(false);
  };

  const changeCurrentComedianId = async (comedianId: string) => {
    try {
      await firestoreServices.setCurrentComedianId(comedianId);
      setCurrentComedianId(comedianId);
    } catch (error) {
      alert('Error updating current comedian ID: ' + error);
    }
  };

  // Round Management Helpers
  const addRound = async (roundName: string) => {
    try {
      const newRound: Round = {
        id: '',
        comedians: [],
        isActive: false,
        name: roundName,
      };
      const newId = await firestoreServices.addRound(newRound);
      newRound.id = newId;
      setRounds([...rounds, newRound]);
    } catch (error) {
      alert('There was an error adding the round:\n' + error);
    }
  };

  const editRound = async (updatedRound: Round) => {
    try {
      await firestoreServices.editRound(updatedRound.id, updatedRound);
      const updatedRounds = rounds.map((round) =>
        round.id === updatedRound.id ? updatedRound : round,
      );
      setRounds(updatedRounds);
    } catch (error) {
      alert('There was an error editing the round:\n' + error);
    }
  };

  const deleteRound = async (roundId: string) => {
    // Check if the round is active and voting is active
    if (isVotingActive && selectedRound?.id === roundId) {
      alert('Please disable voting before deleting the active round.');
      return;
    }
    try {
      await firestoreServices.deleteRound(roundId);
      const updatedRounds = rounds.filter((round) => round.id !== roundId);
      setRounds(updatedRounds);
    } catch (error) {
      alert('There was an error deleting the round:\n' + error);
    }
  };

  const activateRound = async (roundId: string) => {
    if (isVotingActive && selectedRound !== undefined) {
      alert('Please disable voting before changing the active round.');
      return;
    }

    let updatedRounds: Round[] = [];

    try {
      // if the round is already active, deactivate it, otherwise activate it
      if (selectedRound?.id === roundId) {
        await firestoreServices.setActiveRound(roundId, true);
        // Update local state
        updatedRounds = rounds.map((round) => ({
          ...round,
          isActive: false,
        }));
      } else {
        await firestoreServices.setActiveRound(roundId, false);
        // Update local state
        updatedRounds = rounds.map((round) => ({
          ...round,
          isActive: round.id === roundId,
        }));
      }
      setIsVotingActive(false); // make sure that voting is disabled when changing rounds
      setRounds(updatedRounds);
    } catch (error) {
      alert('Error updating active round:\n' + error);
    }
  };

  const handleOpenAddRoundModal = () => {
    setRoundBeingEdited(undefined);
    setShowRoundModal(true);
  };

  const handleOpenEditRoundModal = (round: Round) => {
    // Check if the round is active and voting is active
    if (isVotingActive && selectedRound?.id === round.id) {
      alert('Please disable voting before editing the active round.');
      return;
    }
    setRoundBeingEdited(round);
    setShowRoundModal(true);
  };

  const handleRoundSave = (data: string | Round) => {
    if (typeof data === 'string') {
      // If data is a string, it's a new round name
      addRound(data);
    } else {
      // If data is a Round object, it's an existing round being edited
      editRound(data);
    }
  };

  const handleVotingToggle = async () => {
    try {
      await firestoreServices.setGlobalVotingStatus(
        !isVotingActive,
        !isVotingActive && selectedRound ? selectedRound.id : '',
      );
      // Update local state
      setIsVotingActive(!isVotingActive);
    } catch (error) {
      console.error('Error updating global voting status:', error);
      alert('Error updating global voting status');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          margin: window.innerWidth <= 768 ? '20px' : '20px',
        }}
      >
        <ComedianList
          comedians={comedians}
          openAddModal={handleOpenAddComedianModal}
          openEditModal={handleOpenEditComedianModal}
          onDelete={deleteComedian}
        />
        <RoundList
          rounds={rounds}
          onAdd={handleOpenAddRoundModal}
          onEdit={handleOpenEditRoundModal}
          onDelete={deleteRound}
          makeRoundActive={activateRound}
        />
      </div>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <SelectedRound
          round={selectedRound}
          comedians={comedians}
          isVotingActive={isVotingActive}
          toggleVote={handleVotingToggle}
          votingResults={votingResults}
          currentComedianId={currentComedianId}
          setCurrentComedianId={changeCurrentComedianId}
        />
      </div>

      <Modal
        title="Manage Comedian"
        isOpen={showMangageComedianModal}
        onClose={() => setShowMangageComedianModal(false)}
      >
        <h3
          style={{
            margin: 0,
            paddingBottom: '5px',
            fontSize: window.innerWidth <= 768 ? '14px' : '16px',
          }}
        >
          Name
        </h3>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <input
            type="text"
            value={newComedianName}
            placeholder="Shane Gillis"
            onChange={(e) => setNewComedianName(e.target.value)}
            style={{
              fontFamily: '"Pixel Operator", sans-serif',
              fontSize: window.innerWidth <= 768 ? '14px' : '16px',
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid grey',
              padding: '10px',
            }}
          />
          <input
            type="file"
            onChange={(e) =>
              setComedianAvatar(e.target.files ? e.target.files[0] : null)
            }
            style={{ margin: '10px 0' }}
          />
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={handleSaveComedian}
            style={{
              width: '30px',
              height: '30px',
              cursor: 'pointer',
              paddingLeft: '10px',
              alignSelf: 'center',
              color: '#9E9E9E',
            }}
            onMouseOver={(e) => (e.currentTarget.style.color = '#E0E0E0')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#9E9E9E')}
          >
            <path
              d="M4 2h14v2H4v16h2v-6h12v6h2V6h2v16H2V2h2zm4 18h8v-4H8v4zM20 6h-2V4h2v2zM6 6h9v4H6V6z"
              fill="currentColor"
            />
          </svg>
          <p style={{ fontSize: window.innerWidth <= 768 ? '8px' : '10px' }}>
            note - if you want to change the avatar just delete and recreate the
            comedian
          </p>
        </div>
      </Modal>

      <RoundManagementModal
        isOpen={showRoundModal}
        onClose={() => setShowRoundModal(false)}
        onSave={(data) => handleRoundSave(data)}
        comedians={comedians}
        roundToEdit={roundBeingEdited}
      />
    </div>
  );
};
export default AdminHome;
