import React from 'react';

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode; // Content of the modal
}

const Modal: React.FC<ModalProps> = ({ title, isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
          zIndex: 99, // One less than the modal's zIndex
        }}
      />
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Adjust for the modal's own dimensions
          backgroundColor: '#231F20',
          border: '1px solid grey',
          padding: '10px',
          zIndex: 100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '20px',
          }}
        >
          <h2 style={{ margin: 0, paddingRight: '10px' }}>{title}</h2>
          {onClose && (
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={onClose}
              style={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
                color: 'red',
              }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#ff7f7f')}
              onMouseOut={(e) => (e.currentTarget.style.color = 'red')}
            >
              <path
                d="M5 5h2v2H5V5zm4 4H7V7h2v2zm2 2H9V9h2v2zm2 0h-2v2H9v2H7v2H5v2h2v-2h2v-2h2v-2h2v2h2v2h2v2h2v-2h-2v-2h-2v-2h-2v-2zm2-2v2h-2V9h2zm2-2v2h-2V7h2zm0 0V5h2v2h-2z"
                fill="currentColor"
              />
            </svg>
          )}
        </div>
        {children}
      </div>
    </>
  );
};

export default Modal;
